<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="商户类型"
                    prop="merchant_type">
        <el-select v-model="form.merchant_type"
                   placeholder="请选择">
          <el-option label="普通商户"
                     value="GENERAL">
          </el-option>
          <el-option label="特邀商户"
                     value="GUEST">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动图片"
                    prop="bg_img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.bg_img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="请输入活动规则"
                  v-model="form.rule">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setGuest, getGuest } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetGuest',
  data () {
    return {
      imgList: [],
      areaAddress: {},
      form: {
        title: '',
        rule: '',
        bg_img_id: '',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        merchant_type: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        bg_img_id: [
          { required: true, message: '请输入上传图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ],
        merchant_type: [
          { required: true, message: '请选择商户类型', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area,
    ImgUpload
  },
  methods: {
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setGuest(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('guests')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getGuest (id) {
      const res = await getGuest({
        id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        activity.bg_img_id = getImgId(activity.bg_img)
        activity.bg_img && this.$set(this.imgList, 0, activity.bg_img)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getGuest(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
